<template>
    <div class="flex flex-col w-full h-screen">
        <div class="flex flex-col w-full mt-6 mb-2 sm:px-4">
            <Header isNotAdd :title="'Resources'" :searchbox="{ placeholder: 'Search', icon: 'SearchIcon' }"
                :searchText="searchText" :onSearchTextChange="(value) => this.onSearchTextChange(value)" />
            <Text size="" weight="" color="black" custom-class="px-4 -mt-4"
                content="Check out this library of articles, videos, and other resources that will help you navigate the next steps of your discovery journey."
                v-if="searchText || (items && items.length > 0)" />
        </div>
        <div class="flex flex-col w-full h-screen px-4 sm:px-8 space-y-2">
            <template v-if="!isLoading && (!items || items.length === 0)">
                <EmptyState class="bg-white" isFull :description="emptyViewBinding.description" />
            </template>
            <template v-else-if="!isLoading">
                <CardWithImage v-for="resource in items" :key="resource.id" v-bind="resource"
                    @onClick="() => $emit('onClick', resource)" />
                <CardFooterWithPageButtons v-bind="pagination" class="px-0 bg-opacity-0" />
            </template>
        </div>
    </div>
</template>

<script>
import Header from "../../molecules/ListViewHeader/WithFilter/ListViewHeader.vue";
import Text from "../../atoms/Text/Text";
import CardFooterWithPageButtons from "../../organisms/Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue";
import CardWithImage from "../../molecules/CardListItem/CardWithImage/CardWithImage";
import EmptyState from "../../organisms/EmptyState/Simple/Simple";
export default {
    components: {
        Header,
        Text,
        CardWithImage,
        CardFooterWithPageButtons,
        EmptyState,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        pagination: {
            type: Object,
            default: () => { },
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        emptyViewBinding: {
            type: Object,
            default: () => { },
        },
        searchText: {
            type: String,
            default: "",
        },
        onSearchTextChange: {
            type: Function,
            default: () => { },
        },
    },
};
</script>

<style scoped>
::v-deep(.add-btn) {
    min-width: 0px;
}
</style>


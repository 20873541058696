<template>
    <Template :isLoading="isLoading" :items="resourceList"
        :pagination="{ totalRecords: resourceList?.length, perPage: 10, currentPage: 1 }" @onClick="onClickItem"
        :emptyViewBinding="emptyViewBinding" :searchText="searchText"
        :onSearchTextChange="(text) => onSearchTextChange(text)" />
</template>

<script>
import Template from "../components/templates/VentureResources/VentureResources";
import { c } from "../components/constants.js";

export default {
    components: {
        Template,
    },
    data() {
        return {
            c,
            resources: [],
            company: null,
            isLoading: true,
            emptyViewBinding: {
                description: "No resources to display just yet!",
            },
            searchText: "",
        };
    },
    async created() {
        const resources = await this.actions.resource.getResources({
            getValues: true,
        });
        this.isLoading = false;
        resources?.map((r) => {
            this.resources.push({
                id: r.id,
                title: r?.name,
                imgSrc: r?.directLink?.source,
                desc: r?.body,
                items: [
                    {
                        icon: "LinkIcon",
                        label: r.type,
                    },
                    {
                        label: "Getting Started",
                    },
                ],
                customClass: "",
            });
        });
    },
    computed: {
        resourceList() {
            if (this.searchText.trim()) {
                return this.resources.filter((item) =>
                    item?.title
                        .toLowerCase()
                        .includes(this.searchText.trim().toLowerCase())
                );
            }
            return this.resources;
        },
    },
    methods: {
        onClickItem(resource) {
            try {
                this.$router.push({ name: 'Ecosystem Resource Detail', params: { id: resource?.id, companyId: this.$route.params?.companyId }, })
            } catch (e) {
                console.log(e)
            }
        },
        onSearchTextChange(searchText) {
            this.searchText = searchText;
        },
    },
};
</script>

<style scoped></style>
